<template>
  <v-card class="pb-5">
    <v-row class="ma-0 primary pa-3 px-4" no-gutters>
        <v-col cols="6" class="col-lg-6 col-xl-6 col-md-4 col-sm-4 mb-3 mb-sm-0 mb-md-0 mb-xl-0 mb-lg-0">
            <v-toolbar-title class="white--text">Webhooks</v-toolbar-title>
        </v-col>
        <v-col cols="12" class="col-lg-6 col-xl-6 col-md-8 col-sm-8 d-flex flex-wrap align-center justify-end">
            <v-btn color="white" @click="openModal='create'">
                <v-icon left>mdi-plus</v-icon>Add endpoint
            </v-btn>
            <v-btn color="white" text small icon class="ml-3" @click="searchable=false;search='';getDataFromApi('refresh')"
                :disabled="loading">
                <v-icon :size="25" :class="{'icon-loader': loading}">mdi-sync-circle</v-icon>
            </v-btn>
        </v-col>
    </v-row>
    <v-row class="ma-0 mx-3 mt-5 mb-2" align="center">
      <v-col cols="12" class="d-flex justify-end">
        <div class="w-45">
          <v-text-field v-model.trim="search" append-icon="mdi-magnify" class="pt-0"
            label="" placeholder="Search Webhook" :disabled="loading" hide-details
            @input="searchDatatable"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="tableItems" :options.sync="options" :server-items-length="totalItems"
        :loading="loading" class="mx-5">
        <template v-slot:[`item.endpoint`]="{item}">
            <a :href="item.endpoint" target="_blank" class="text-decoration-none">
                {{ item.endpoint }}
            </a>
        </template>
        <template v-slot:[`item.events`]="{item}">
            <template v-for="event in item.events">
                <v-chip color="primary lighten-5" small text-color="white" :key="event" class="mr-2">
                    {{ event }}
                </v-chip>
            </template>
        </template>
        <template v-slot:[`item.secret`]="{item}">
            <v-tooltip top attach :disabled="!item.secret">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-if="item.secret" :color="item.copyTxt ? 'success':'info'" small label :outlined="item.copyTxt"
                        @click="toogleCopyTxt(item, `${item.id}-secret`)" :ref="`${item.id}-secret`" :id="`${item.id}-secret`"
                        v-bind="attrs" v-on="on">
                        {{ item.secret }}
                        <v-icon size="13" class="ml-2" @click="toogleCopyTxt(item, `${item.id}-secret`)">mdi-content-copy</v-icon>
                    </v-chip>
                </template>
                <span>{{ item.copyTxt ? 'Copied!' : 'Copy' }}</span>
            </v-tooltip>
        </template>
        <template v-slot:[`item.action`]="{item}">
            <v-icon small class="mr-2 cursor-pointer" color="primary" @click="openModal=item.id">
                mdi-pencil
            </v-icon>
            <v-icon small class="mr-2 cursor-pointer" color="danger" @click="notify=item.id">
                mdi-trash-can
            </v-icon>
        </template>
    </v-data-table>
    <Notify v-if="notify" @close="notify=null" :loading="deleteLoading" @saved="deleteWebhookItem()"
      title="are you sure" success=", delete it!"/>
    <webhook-modal v-if="openModal" :open="openModal" @close="openModal=null" @save="openModal=null;getDataFromApi('saved');"/>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import { Notify } from "@/components/common";

export default {
    components: {
        Notify,
        WebhookModal: () => import("./WebhookForm.vue")
    },
    data() {
        return {
            totalItems: 0,
            tableItems: [],
            search: '',
            loading: true,
            options: {},
            notify: null,
            deleteLoading: false,
            searchable: false,
            headers: [
                { text: 'Endpoints', value: 'endpoint' },
                { text: 'Events', value: 'events' },
                { text: 'Secret', value: 'secret' },
                {text: 'Action', value: 'action', sortable: false}
            ],
            pageLink: `${window.VUE_APP_MS_URL}agent/webhooks`,
            prevOptions: null,
            openModal: null
        }
    },
    watch: {
        options: {
            handler () {
            this.getDataFromApi();
            },
            deep: true,
        }
    },
    created() {
        this.searchDatatable = _.debounce(this.searchDatatable, 500);
    },
    methods: {
        ...mapActions(['getModel', 'deleteWebhook']),

        searchDatatable() { this.getDataFromApi('search') },
        getDataFromApi (type) {
            this.loading = true;
            this.getDTDetails(type).then(data => {
                this.tableItems = data.items.map(item => {
                    item.copyTxt = false;
                    return item;
                });
                this.totalItems = data.total;
                this.loading = false;
            }).catch(err => {
                this.tableItems = [];
                this.totalItems = 0;
                this.loading = false;
            });
        },
        getDTDetails (type) {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options,
                    endPoint = new URL(this.pageLink);
                    endPoint.searchParams.set("per_page", itemsPerPage);
                    endPoint.searchParams.set("page", page);
                    if(type) {
                        this.options.page = 1;
                        endPoint.searchParams.set("page", 1);
                    }
                    if(this.search !== '') {
                        endPoint.searchParams.set("q", this.search);
                    }
                this.getAPIdata(endPoint, this.options, type).then(resp => {
                    let { items, total } = resp;
                    if (sortBy.length === 1 && sortDesc.length === 1) {
                        items = items.sort((a, b) => {
                            const sortA = a[sortBy[0]]
                            const sortB = b[sortBy[0]]

                            if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                            } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                            }
                        })
                    }
                    this.prevOptions = this.options;
                    resolve({
                        items,
                        total,
                    })

                }).catch(err => reject(err));
            });
        },
        getAPIdata(endPoint, options, type) {
            const { page, itemsPerPage } = options;
            return new Promise((resolve, reject) => {
                if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
                    resolve({items: this.tableItems, total: this.totalItems});
                    return true;
                }
                this.getModel(endPoint.toString()).then(resp => {
                    resolve(resp);
                }).catch(err => reject(err));
            });
        },
        toogleCopyTxt(item, refName) {
            if (!item.copyTxt) {
                this.$helpers.copyTextFunc(this.$refs[refName], 'noInput');
                item.copyTxt = true;
                setTimeout(() => {
                    item.copyTxt = false;
                }, 5000);
            }
        },
        deleteWebhookItem() {
            this.deleteLoading = true;
            this.deleteWebhook(this.notify).then(() => {
                this.notify = null;
                this.deleteLoading = false;
                this.getDataFromApi('delete');
            }).catch(err => this.deleteLoading = false);
        },
    },
};
</script>
